.text-field-wrapper {
    display: block;
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    margin-bottom: 10px;
}
  
.text-field-wrapper .field-input{
    font-family: monospace;
    border: 0;
    border-bottom: 2px solid gray;
    outline: 0;
    font-size: 1.15rem;
    padding: 7px 4px;
    transition: border-color 0.2s;
}
  
.text-field-wrapper .field-label {
    font-family: monospace;
    position: relative;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    margin: 4px 0px;
    color: rgb(85, 84, 84);
}
  
.text-field-wrapper .field-input:focus {
    border-bottom: 2px solid blue;
}