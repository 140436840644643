/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* Reset CSS Above */

#edit-img {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.save-dialog-login {
    text-align: center;
    padding: 1rem 0;
    font-size: 1.4rem;
    font-family: monospace;
}

.pixel-app {
    padding-top: 1rem;
}

#main-canvas {
    background-color: white;
    -moz-box-shadow: 0 0 0.1em #000;
    -webkit-box-shadow: 0 0 0.1em #000;
    box-shadow: 0 0 0.1em #000;
}

#create-button {
    margin-left: 0.5em;
    margin-bottom: 0.5em;
}

#center-grid {
    margin-right: auto;
    margin-left: auto;
}

#post-dialog-image {
    background-color: white;
    -moz-box-shadow: 0 0 0.1em #000;
    -webkit-box-shadow: 0 0 0.1em #000;
    box-shadow: 0 0 0.1em #000;
    margin: 0.5rem;
}

#editor-buttons {
    padding-left: 0;
    padding-right: 0;
}

.layer-image canvas {
    background-color: white;
    -moz-box-shadow: 0 0 0.1em #000;
    -webkit-box-shadow: 0 0 0.1em #000;
    box-shadow: 0 0 0.1em #000;
}


.color-tool-select-id{
    white-space: pre-wrap;
    color: black;
}

#selected-tool-id{
    font-size: 1.2rem;
    font-family: monospace;
}

#color-select {
    height: 1.5em;
    width: 1.5em;

}

.container {
    position: relative;
    overflow: hidden;
    height: 1.5em;
    width: 1.5em;
}

.front {
    z-index: 10;
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    background-color: #3f51b5;
    color: white;
    -moz-box-shadow: 0 0 2px #000;
    -webkit-box-shadow: 0 0 2px #000;
    box-shadow: 0 0 2px #000;
    display: inline-block;
    padding: 1em 12px;
    margin: 1em;
    cursor: pointer;
}

.custom-file-upload:hover {
    background-color: cornflowerblue;
}

.custom-file-upload:active {
    background-color: cadetblue;
}

.sliders {
    padding: 1em;
    width: 10px
}

.pixel-app:focus {
    outline: none;
}

.palette-tab {
    padding-bottom: 1em;
}

.cp-hue {
    position: relative;
    width: 265px;
    height: 3em;
}

.cp-alpha {
    position: relative;
    width: 265px;
    height: 3em;
}
.cp-sat {
    position: relative;
    width: 265px;
    height: 9em;
}

.cp-palette {
    max-height: 20em;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.cp-palette::-webkit-scrollbar {
    width: 0.5em;
}

.cp-palette::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.layers {
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.layers::-webkit-scrollbar {
    width: 0.5em;
}

.layers::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}