.text-area-wrapper {
    display: block;
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    margin-bottom: 10px;
}
  
.text-area-wrapper .area-input{
    font-family: monospace;
    border: 2px solid gray;
    outline: 0;
    font-size: 1rem;
    padding: 7px 4px;
    transition: border-color 0.2s ;
}
  
.text-area-wrapper .area-label {
    font-family: monospace;
    position: relative;
    display: block;
    margin: 4px 0px;
    transition: 0.2s;
    font-size: 1rem;
    color: rgb(85, 84, 84);
}
  
.text-area-wrapper .area-input:focus {
    border: 2px solid blue;
}

.asterisk {
    color: red;
}