.form button input[type="submit"] {
    border: none;
    background: none;
    color: unset;
    cursor: pointer;
    padding: 0.2em;
}

.form button:hover input[type="submit"] {
    background: aliceblue;
}


@media screen and (max-width: 550px) {
    .auth-options button {
        padding:  0 0.5rem;
        font-size: 0.9rem;
    }
}

.page {
    padding: 1em;
}

.form label,
.form input {
    display: block;
}

.form label {
    margin-bottom: 0.2rem;
}
.form input {
    margin-bottom: 1rem;
}

.form input[type="text"],
.form input[type="password"],
.form input[type="email"]{
    width: 100%;
    max-width: 30em;
    padding: 0.3rem;
    border: 1px solid lightgray;
    border-radius: 8px;
    font-size: 1.1rem;
    background-color: lightgrey;
}

.form input:focus {
    outline: none;
    border: 1px solid cornflowerblue;
    box-shadow: inset 0px 0px 10px rgba(38,142,228,0.5);
}

.form input[type="submit"] {
    padding: 0.3rem 0.5rem;
    font-size: 1.1rem;
    border-radius: 8px;
    border: 1px solid darkolivegreen;
    background-color: lightgreen;
}

.error-notice {
    width: 100%;
    max-width: 30em;
    padding: 0.5em;
    border-radius: 8px;
    margin: 1rem 0;
    border: 1px solid orangered;
    background-color: lightcoral;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.error-notice button {
    background-color: red;
    color: white;
    border: 0;
}
