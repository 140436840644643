.username-h2 {
    color:rgb(50, 50, 50);
    padding: 1rem 2rem;
    font-size: 2rem;
    font-family: monospace;
    display: -webkit-inline-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
}

.ag-title {
    font-family: 'Work Sans', sans-serif;
    font-size: 2.75rem;
    padding-left: 1.5rem;
}

.user-header {
    display: flex;
    padding-left: 2rem;
    border-bottom: 1px solid rgb(218, 218, 218);
}

main {
    display: flex;
    flex-wrap: wrap;
    font-family: monospace;
}

.card {
    align-self: flex-start;
    box-sizing: border-box;
    background-color: white;
    max-width: 100%;
    flex-basis: 100%;
    color: rgb(72, 72, 72);
}

.card img {
    width: 100%;
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
}

.card .header {
    display: flex;
    align-items: center;
    padding: 16px;
}
.card .header .avatar {
    flex: 0 0 auto;
    margin-right: 16px;
    background-color: gray;
    color: white;
    border-radius: 50%;
}

.card .header .content {
    flex: 1 1 auto;
}

.card .header .action .author{
    text-align: center;
}

.card .header .content .title-user{
    font-weight: bold;
    padding-top: 4px;
    font-size: 1.2rem;
}

.privatepost{
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    fill: black;
}

.card .header .action {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card .header .action .profilesvg:hover {
    background-color: rgb(194, 192, 192);
    border-radius: 30%;
}

.card .header .action .dropdown{
    position: relative;
    padding: 2px;
    transition: 0.4s;
}
.card .header .action .dropdown:hover {
    background-color: rgb(194, 192, 192);
    border-radius: 30%;
}

.card .header .action svg{
    width: 30px;
    height: 30px;
    fill: #3f51b5;
}

.card .header .action .dropdown-content{
    display: none;
    position: absolute;
    padding: 0.1rem;
    border: 1px solid #e3e9ed;
    background-color: white;
    border-radius: 10%;
    right: -11px;
}

.card .header .action .dropdown-content li{
    padding: 1rem 1rem;
    font-size: 1.2rem;
    font-family: monospace;
    text-align: center;
    background-color: white;
    border-radius: 10%;
}

.card .header .action .dropdown-content li:hover{
    background-color: rgb(197, 197, 197);
}

.card .header .action .dropdown-content li:active{
    background-color: rgb(129, 129, 129);
    color: white;
}

.dropdown-active {
    display: block;
}

.card .header .action .dropdown:hover .dropdown-content {
    display: block;
}

.card .header .action .dropdown:active .dropdown-content {
    display: block;
}

.profilesvg{
    width: 40px;
    float: right;
    transition: 0.4s;
    fill: #3f51b5;
}

.card .footer {
    padding: 16px;
    font-size: 0.75rem;
}

.card .footer .description {
    border-top: 1px solid grey;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 4px;
    max-height: 2.25rem;
    overflow-x: auto;
    overflow-y: hidden;
}

.more-less-btn {
    font-weight: bold;
    display: inline-block;
    padding: 4px 0;
}

.more-less-btn:hover {
    background-color: #dadada;
    cursor: pointer;
}

.more-less-btn:active {
    background-color: #dadada;
}

/* Nav */
.main-nav {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #3f51b5;
    color: white;
}

.nav-links {
    display: flex;
    align-self:center;
}

.search {
    position: relative;
    display: flex;
    justify-content: center;
}

.ag-title {
    font-family: 'Work Sans', sans-serif;
    font-size: 2.0rem;
    padding-left: 1.0rem;
}

.nav-links input{
    width: 15ch;
    padding: 5px 10px 5px 15px;
    border: 2px solid #3f51b5;
    outline: none;
    border-radius: 15px;
    transition: padding 0.3s;
    font-family: monospace;
    font-size: 1.0rem;
}

.nav-links input:focus {
    border: 2px solid #000000;
    border-radius: 15px;
    padding: 5px 15px 5px 10px;
}

@media (min-width: 395px) {
    .main-nav {
        flex-direction: row;
        align-items: center;
    }
    .nav-links input{
        width: 10ch;
    }
}

@media (min-width: 480px) {
    .card {
        border: 1px solid rgb(218, 218, 218);
        margin: 4px;
        border-radius: 6px;
        max-width: calc(50% - 8px);
        flex-basis: calc(50% - 8px);
    }

    .ag-title {
        font-size: 2.25rem;
        padding-left: 1.25rem;
    }

    

    .nav-links {
        align-self: flex-end;
    }

    .user-header {
        border: 0;
    }
}

@media (min-width: 575px) {
    .nav-links input{
        width: 15ch;
    }

    .main-nav {
        padding: 0.5rem 0;
    }
}

@media (min-width: 768px) {
    .card {
        max-width: calc(33.333333% - 8px);
        flex-basis: calc(33.333333% - 8px);
    }
    
    .ag-title {
        font-size: 2.75rem;
        padding-left: 1.5rem;
    }

    .nav-links input{
        width: auto;
        padding: 5px 10px 5px 26px;
        border: 2px solid #3f51b5;
        outline: none;
        border-radius: 15px;
        transition: padding 0.3s;
        font-family: monospace;
        font-size: 1.0rem;
    }
    
    .nav-links input:focus {
        border: 2px solid #000000;
        border-radius: 15px;
        padding: 5px 26px 5px 10px;
    }
    
}

@media (min-width: 1024px) {
    .card {
        max-width: calc(25% - 8px);
        flex-basis: calc(25% - 8px);
    }
}

@media (min-width: 1200px) {
    .card {
        max-width: calc(20% - 8px);
        flex-basis: calc(20% - 8px);
    }
}
