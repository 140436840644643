/* Drawer */

.drawer-container {
    display: none;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.from-left {
    left: 0;
}

.from-right {
    right: 0;
}

.drawer {  
    position: fixed;
    z-index: 3;
    top: 0;
    overflow-x: hidden;
    background-color: white;
    width: max-content;
    max-width: 0;
    height: 100%; 
    transition: max-width 0.4s;
}

.drawer-open{
    display: block;
}

.canvas-buttons {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    justify-content: space-around;
    height: 100%;
}

.no-drawer {
    display: none;
    height: auto;
}

.is-drawer {
    display: inline;
}

@media (min-width: 1010px) {
    .no-drawer {
        display: flex;
    }

    .is-drawer {
        display: none;
    }
}


.canvas-buttons h2 {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: monospace;
    color: rgb(63, 63, 63);
}

.main-canvas-section button {
    background-color: #3f51b5;
    margin: 0.5em;
    padding: 0.8em 0.6em;
    border: 0;
    border-radius: 8px;
    font-family: monospace;
    font-size: 1.2em;
    font-weight: 600;
    color: cornsilk;
    letter-spacing: 1px;
    transition: 0.3s;
}

.main-canvas-section main {
    display: flex;
    justify-content: center;
}

.main-canvas-section button:hover {
    border-radius: 1.5em;
    background-color: #95a5ff;
    color:black;
    cursor: pointer;
}

.main-canvas-section button:active {
    background-color: #2d42b9;
    color: white;
    transition: 0s;
}

.main-canvas-section .canvas-editor-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0.2rem;
}

.main-canvas-section .canvas-utility-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
}

/* Icon Button */
.icon-btn-wrapper {
    display: flex;
    width: 100%;
}

.icon-btn {
    background-color: #3f51b5;
    padding: 1.0rem;
    border-radius: 10%;
    transition: 0.4s;
    width: 20px;
    height: 20px;
    fill:white;
}

.icon-btn:hover {
    background-color: #a1aef8;
    border-radius: 25%;
    fill: black;
}

.icon-btn:active {
    background-color: #1a30b1;
    fill: rgb(218, 255, 10);
    transition: 0s;
}

.icon-label {
    align-self: center;
    padding-left: 0.5rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-family: monospace;
}

@media (min-width: 400px) {
    .icon-btn {
        padding: 1.3rem;
        width: 26px;
        height: 26px;
    }

    .icon-label {
        padding-left: 1rem;
        font-size: 1.6rem;
    }
    .canvas-buttons {
        margin: 0 3rem;
    }
}

/* Color Picker */

input[type="color"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
}

.color-picker-wrapper {
    position: relative;
    padding: 0.5rem;
    margin: 4px 4px;
    border: 1px solid black;
    border-radius: 20%;
    background-color: black;
    transition: border-radius 0.2s;
}

.color-picker-wrapper:hover {
    border-radius: 50%;
}

.color-picker-icon {
    width: 36px;
    height: 36px;
}