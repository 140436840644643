
.dialog-container {
    display: none;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.dialog-open{
    display: block;
}

.dialog-content {  
    display: none;
    position: fixed;
    z-index: 3;
    top: 0;
    overflow-x: hidden;
    background-color: white;
    width: max-content;
    max-width: 85%;
    height: 75%; 
    transition: display 0.4s;
}